import './overlay.scss';

import axios from 'axios';
import { checkAndLoadFiles, createElementFromHTML } from '../../utilities/js/helper';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Overlay {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-overlay',
            onAjaxLoaded: null,
            onClosed: null,
            loader: null
        };

        this.settings = Object.assign({}, defaults, options);
        this.overlayType = 'content';

        this.$overlay = element;

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.$overlay.addEventListener('click', (e) => {
            e.preventDefault();
            this.openOverlay();
        });

        if (this.$overlay.getAttribute('data-overlay-open') !== null) {
            window.setTimeout(() => {
                this.openOverlay();
            }, 500);
        }
    }

    openOverlay () {
        const overlayId = this.$overlay.getAttribute('data-overlay');
        const $overlayAjaxURL = this.$overlay.getAttribute('data-overlay-ajax-url');
        const $currentOverlay = document.querySelector('.overlay.is--active');

        this.$overlayTarget = document.getElementById(overlayId);

        if ($currentOverlay) {
            const overlayAPI = $currentOverlay.API;
            overlayAPI.closeOverlay();
        }

        if ($overlayAjaxURL !== null) {
            this.loadOverlay($overlayAjaxURL, () => {
                this.showOverlay();
            });
            return false;
        }

        if (this.$overlayTarget === null) {
            const uri = this.$overlay.getAttribute('href');
            this.overlayType = 'ajax';

            if (uri !== null) {
                this.loadOverlay(uri, () => {
                    this.showOverlay();
                });
            }
        } else {
            this.showOverlay();
        }
    }

    loadOverlay (uri, callback) {
        let $loader = null;

        if (this.settings.loader !== null) {
            $loader = createElementFromHTML(this.settings.loader);
        }

        if ($loader !== null) {
            this.$overlay.appendChild($loader);
        }

        axios({
            url: uri
        })
            .then((response) => {
                if (typeof response.data !== 'undefined') {
                    const content = response.data;
                    const $content = createElementFromHTML(content);

                    checkAndLoadFiles($content);

                    document.querySelector('body').appendChild($content);
                    const overlayId = $content.getAttribute('id');
                    this.$overlayTarget = document.getElementById(overlayId);

                    if ($loader !== null) {
                        $loader.parentNode.removeChild($loader);
                    }

                    if (typeof this.settings.onAjaxLoaded === 'function') {
                        this.settings.onAjaxLoaded(this.$overlayTarget);
                    }

                    if (typeof callback === 'function') {
                        callback();
                    }
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }

    showOverlay () {
        if (this.overlayType === 'ajax') {
            window.setTimeout(() => {
                this.$overlayTarget.classList.add('is--active');
            }, 250);
        } else {
            this.$overlayTarget.classList.add('is--active');
        }

        this.$overlayScroller = this.$overlayTarget.querySelector('[data-overlayscroller]');
        disableBodyScroll(this.$overlayScroller);

        const $$exists = this.$overlayTarget.querySelectorAll('.overlay__exit');
        $$exists.forEach(($exit) => {
            $exit.addEventListener('click', (e) => {
                e.preventDefault();
                this.closeOverlay();
            });
        });

        this.$overlayTarget.API = this;
    }

    closeOverlay () {
        this.$overlayTarget.classList.remove('is--active');

        enableBodyScroll(this.$overlayScroller);

        if (this.overlayType === 'ajax') {
            window.setTimeout(() => {
                this.$overlayTarget.parentNode.removeChild(this.$overlayTarget);
                this.$overlayScroller = null;
            }, 250);
        }

        if (typeof this.settings.onClosed === 'function') {
            this.settings.onClosed(this.$overlayTarget);
        }
    }
}

export { Overlay };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$overlay = $context.querySelectorAll('[data-overlay]');
        for (let i = 0; i < $$overlay.length; i++) {
            const $overlay = $$overlay[i];

            const overlayAPI = new Overlay($overlay);
            $overlay.API = overlayAPI;
        }
    }
});
